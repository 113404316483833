import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

// Containers
const TheContainer = () =>
    import ('@/containers/TheContainer')

// Views
const Dashboard = () =>
    import ('@/views/Dashboard')

const allUser = () =>
    import ('@/views/user-signup/List')
const activeUser = () =>
    import ('@/views/user-signup/ActiveList')
const blockUser = () =>
    import ('@/views/user-signup/BlockList')
const profile = () =>
    import ('@/views/user-signup/Form')

const setting = () =>
    import ('@/views/setting/Form')
const update = () =>
    import ('@/views/update/Form')
const changePass = () =>
    import ('@/views/change-pass/Form')

// Views - Pages
const Page404 = () =>
    import ('@/views/pages/Page404')
const Page500 = () =>
    import ('@/views/pages/Page500')
const Login = () =>
    import ('@/views/pages/Login')
const Register = () =>
    import ('@/views/pages/Register')

Vue.use(Router)

const routes = [{
        path: '/',
        redirect: '/dashboard',
        name: 'Home',
        component: TheContainer,
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'articles',
                name: 'Articles',
                component: () => import ('@/views/articles/List'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'articles/create',
                name: 'Articles',
                component: () => import ('@/views/articles/Form'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'quizs',
                name: 'Articles',
                component: () => import ('@/views/quizs/List'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'pages/create',
                name: 'Pages',
                component: () => import ('@/views/page/Form'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'pages',
                name: 'Pages',
                component: () => import ('@/views/page/List'),
                meta: {
                    requiresAuth: true
                }
            },
            // {
            //     path: 'users',
            //     redirect: '/base/cards',
            //     name: 'Users',
            //     component: {
            //         render(c) { return c('router-view') }
            //     },
            //     children: [{
            //             path: 'all',
            //             name: 'Popovers',
            //             component: allUser,
            //             meta: {
            //                 requiresAuth: true
            //             }
            //         },
            //         {
            //             path: 'active',
            //             name: 'Popovers',
            //             component: activeUser,
            //             meta: {
            //                 requiresAuth: true
            //             }
            //         },
            //         {
            //             path: 'block',
            //             name: 'Popovers',
            //             component: blockUser,
            //             meta: {
            //                 requiresAuth: true
            //             }
            //         },
            //         {
            //             path: 'profile',
            //             name: 'Popovers',
            //             component: profile,
            //             meta: {
            //                 requiresAuth: true
            //             }
            //         }
            //     ]
            // },
            {
                path: 'general-setting',
                redirect: '/base/cards',
                name: 'General Setting',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'send-notification',
                        name: 'Popovers',
                        component: () => import ('@/views/notification/Form'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'setting',
                        name: 'Popovers',
                        component: setting,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: '',
                        name: 'Popovers',
                        component: setting,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'sections',
                        name: 'Popovers',
                        component: () => import ('@/views/section/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'article-categories',
                        name: 'Popovers',
                        component: () => import ('@/views/article-categories/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'quiz-categories',
                        name: 'Popovers',
                        component: () => import ('@/views/quiz-categories/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'quiz-sub-categories',
                        name: 'Popovers',
                        component: () => import ('@/views/quiz-sub-categories/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'ads',
                        name: 'Popovers',
                        component: () => import ('@/views/ads/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'companies',
                        name: 'Popovers',
                        component: () => import ('@/views/company/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'change-password',
                        name: 'changePassword',
                        component: changePass,
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '404',
                name: 'Page404',
                component: Page404,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '500',
                name: 'Page500',
                component: Page500,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: 'login',
                name: 'Login',
                component: Login,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: 'register',
                name: 'Register',
                component: Register,
                meta: {
                    requiresAuth: false
                }
            }
        ]
    }
]


const router = new Router({
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/pages/login')
    } else {
        next()
    }
})

export default router