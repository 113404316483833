import { policyList, playInList, optionList } from './custom'

const state = {
	commonObj: {
		hasDropdownLoaded: true,
		quizSubCategoryList: [],
		quizCategoryList: [],
		sectionList: [],
		adsList: [],
		articleCategoryList: [],
		companyList: [],
		optionList: optionList,
	},
	token: localStorage.getItem('access_token') || '',
	user: '',
	sidebarMinimize: false,
	sidebarShow: false,
	lang: 'en', 
	allMessage:[],
	static: {
		loading: false,
		listReload: false,
        dateFormat: 'DD/MM/YYYY',
        fiscaleYear: 'YYYY-YYYY',
        timeFormat: 'h:m',
        unitOfTime: 'day'
	},
	list: []
} 
export default state